import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dataContainer: {
        padding: '4rem 1rem 1rem 0rem',
        maxWidth: '40rem'        
    },
    body1: {
        
    },
    label: {
        fontWeight: 800,
        color: theme.palette.primary.main,
        marginTop: '.25rem',
        display: 'inline-block' 
    },
    researchHeading: {
        fontWeight: 800,
        color: '#f00'
    },
    factSheet: {
        color: theme.palette.tertiary.main,
    },
    subCat: {
        marginLeft: '1rem',
    },
    monospace: {
        
        marginLeft: '.5rem'
    },
    populationOrigins: {
        marginTop: '1rem',
        marginBottom: '.5rem'
    },
    population: {
        marginLeft: '1.5rem',
        marginTop: '.5rem',
        fontFamily: 'monospace',
    },
    smMap: {
        float: 'right',
        margin: '1.5rem 0 1.5rem 1.5rem'
    },
    list: {
        marginLeft: '1rem'
    }
}));


export default function TuleLakeData() {
    const classes = useStyles();

    return (
        <div className={classes.dataContainer}>
            <Typography variant='h3' className={classes.researchHeading}>
                Research: Tule Lake Relocation Camp</Typography>
            <Typography variant='body1' className={classes.factSheet}>

                <p>Question 28 of the relocation camp registration questionnaire, filled out by all internees, confronted imprisoned Japanese Americans with a pledge of loyalty to the United States. In 1943, Tule Lake was selected as the "Segregation Center" where dissidents who would not pledge their loyalty to the US were to be isolated from the rest of the Japanese American prisoners. </p>

                <p><span className={classes.label}>Location:</span> Klamath Falls Basin in Northern California, just south of the Oregon border. The closest town was Newell, California. Tule Lake is located just across the road from Lava Beds National Monument and the site of the Modoc War of 1872-73.</p>

                <img src={`usmap.sm.gif`} className={classes.smMap} alt="small map of Japanese-American internment camps in western USA" />

                <p><span className={classes.label}>Land: </span> Federal reclamation project land. </p>

                <p><span className={classes.label}>Size:</span> 26,000 acres.</p>

                <p><span className={classes.label}>Climate: </span> Relatively mild, for a WRA camp site; the land was a dry lake bed covered with sagebrush 4,000 feet above sea level. </p>
                
                <p className={classes.populationOrigins}><span className={classes.label}>Population Origins: </span></p>               
                <p className={classes.population}>
                    Sacremento County, CA (4,984)
                    <br />King County, WA (2,703)
                    <br />Placer County, WA (1,807)
                    <br />Pierce County, WA (946)
                    <br />Yuba County, OR (476)
                    <br />Hood River County, OR (425)
                </p>
                <p>The population was a rough split between rural and urban.</p>

                <p><span className={classes.label}>Opening date: </span> May 27, 1942.</p> 
                <p><span className={classes.label}>Peak population:</span> 18,789; peak population occurred after Tule Lake had become a "Segregation Center"  </p>
                <p><span className={classes.label}>Date of peak: </span> December 25, 1944.</p>                
                <p><span className={classes.label}>Closing Date: </span> March 20, 1946.</p>
                

                <p><span className={classes.label}>Project director:</span> Elmer Shirrell, Harvey Coverly, and Raymond Best.</p>

                <p><span className={classes.label}>Community Analyst: </span> Marvin K. Opler.</p>

                <p><span className={classes.label}>Newpaper(s): </span> <em>Tulean Dispatch</em> (June 15, 1942 to October 30, 1943).</p>

                <p><span className={classes.label}>% who answered question 28 of the loyalty questionnaire positively: </span> 84.4%</p>

                <p><span className={classes.label}>Number and percentage of eligible citizen males inducted directly into ARMED FORCES: </span> 57 (0.5%); Tule Lake had the lowest percentage of eligible citizen males inducted into the armed forces.</p>

                <p><span className={classes.label}>Industry:</span>Tule Lake had a cabinet shop and a bakery which produced goods for internal consumption. </p>

                <p><span className={classes.label}>History: </span> Tule Lake Relocation Center, 1942 to 1943: Tule Lake experienced much unrest. A farm laborers strike occured on August 15, 1942 over the lack of promised goods and salaries. Packing shed workers struck in September, while a mess hall workers protest took place in October, 1942. This culminated with large numbers of people refusing to register for the draft.</p>

                <p>With the decision to segregate the "loyal" from the "disloyal" on the basis of the 1943 loyalty questionnaire, Tule Lake was chosen as the camp where the "disloyals" would be isolated. Tule Lake became the "Tule Lake Segregation Ceneter" in the fall of 1943. </p>


                <Typography className={classes.TuleLakeHistory} variant='h4'>Tule Lake Segregation Center, 1943 to 1946</Typography>

                <p>Tule Lake "Segregation Center" was created following disturbances associated with loyalty questionnaires administered by the War Department and WRA during February and March, 1943. The negative reponses by many Japanese Americans, were in reality protests against their own removal and incarceration. The WRA was pressured by Congress, the Army, the Japanese American Citizens Leage, and its own project directors, to isolate the "disloyal" in a separate center.</p>

                <p>On July 15, 1943, the WRA announced that the following people would be sent to Tule Lake Segregation Center: aliens and American citizens of Japanese descent who had applied for repatriation or expatriation to Japan (7,222 persons); those who had answered the loyalt y questions in the negative, or had refused to answer (4,785 persons); those who had been denied clearance to leave the centers; and paroled aliens from from Department of Justice internment camps who were recommended for detention. The WRA also included all persons in their centers it believed to be anti-administration or "troublemakers," as well as their family members. 6,000 original residents of Tule Lake Relocation Center chose to remain in the camp rather than undergo another forced move. The move to Tule lake took place during September and October, 1943.</p>

                <p>A newly erected heavy wire mesh "man proof" fence held them inside, while elevated block houses and watch towers with armed sentries prevented escape. Outside the fence, a battalion of military police with armored cars and tanks stood in full view of the residents. Permission to resettle was denied for all regardless of loyalty status. Self government, as establioshed in other centers, was not allowed, although an advisory council to the administration was formed.</p>

                <p>In October, 1943, project director Raymond Best refused to negotiate with the internee community organization (the Daihyo Sha Kai) and its Negotiating Committee. A meeting followed in which national director Dillon Myer and other administraters were surrounded by thousands of peaceful residents/internees who came to support their representatives.</p>

                <p>The U.S. Army entered Tule Lake Segregation camp on November4, 1943, and martial law was declared nine days later. The center was not returned to civilian control until January 15, 1944. A curfew kept people indoors, and ended recreational activities. The Army arrested anyone suspected of being anti-administration without hearings or trials. A stockade was constructed to house these people.</p>

                <p>Of the 18,422 persons isolated in the center, more than one fourth, including 4,517 citizens, were classified as loyal. Many of those classified as "disloyal" were there as a result of protest rather than actual loyalty to Japan. Wayne Collins, attorney for those who later renounced their citizenship, argued that even the most fanatically pro-Japanese were once loyal Americans, but changed their allegiance because of injustices suffered during and after the forced evacuation from their homes in 1942.</p>

                <p>In July 1944, the renunication law was passed. It allowed for the internees to renunciate their U.S. citizenship. On December 17, 1944, the government announced that all WRA centers would close within the year. Tule Lake residents had developed an almost pathological fear of violence they believed awaited them in West Coast communities. They saw the segregation center as a safe haven from the hostility of other Americans on the outside. Renunciation of citizenship was seen as the only means to prevent forced resettlement. 5,589 Nisei gave up their citizenship. Only a small number were actually deported; 5,409 Nisei asked for their citizenship back. </p>
                
                <p><span className={classes.label} >Source:</span> <em>Japanese American History: An A to Z Reference, 1868 to the Present</em>, by Brian Niiya. New York: Facts on File, 1993. This information is provided with the permission from the Japanese American National Museum and Brian Niiya, 1997. 
                </p>
                
                <p><span className={classes.label}>Edited for exhibition by Masumi Hayashi, 1994. </span></p>
                
            </Typography>
        </div>
    );
} 