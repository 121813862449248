import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dataContainer: {
        padding: '4rem 1rem 1rem 0rem',
        maxWidth: '40rem'        
    },
    body1: {
        
    },
    label: {
        fontWeight: 800,
        color: theme.palette.primary.main,
        marginTop: '.25rem',
        display: 'inline-block' 
    },
    researchHeading: {
        fontWeight: 800,
        color: '#f00'
    },
    factSheet: {
        color: theme.palette.tertiary.main,
    },
    subCat: {
        marginLeft: '1rem',
    },
    monospace: {
        
        marginLeft: '.5rem'
    },
    populationOrigins: {
        marginTop: '1rem',
        marginBottom: '.5rem'
    },
    population: {
        marginLeft: '1.5rem',
        marginTop: '.5rem',
        fontFamily: 'monospace',
    },
    smMap: {
        float: 'right',
        margin: '1.5rem 0 1.5rem 1.5rem'
    },
    list: {
        marginLeft: '1rem       '
    }
}));


export default function JeromeData() {
    const classes = useStyles();

    return (
        <div className={classes.dataContainer}>
            <Typography variant='h3' className={classes.researchHeading}>
                Research: Jerome Relocation Camp</Typography>
            <Typography variant='body1' className={classes.factSheet}>
                <p><span className={classes.label}>Location:</span> Drew and Chicot Counties, southeastern Arkansas.</p>

                <img src={`usmap.sm.gif`} className={classes.smMap} alt="small map of Japanese-American internment camps in western USA" />

                <p><span className={classes.label}>Land: </span> Farm Security Administration land.</p>
                <p><span className={classes.label}>Size:</span> 10,000 acres.</p>

                <p><span className={classes.label}>Climate: </span> Swamp Land; green and tropical; humid.</p>

                <p><span className={classes.label}>Peak population:</span> 8,497 </p>

                <p><span className={classes.label}>Date of peak: </span> February 11, 1943.</p>
                <p><span className={classes.label}>Opening date: </span> October 6, 1942.</p>

                <p><span className={classes.label}>Closing Date: </span> June 30, 1944; Jerome was in operation only 634 days, the shortest of any camp. </p>
                



                <p className={classes.populationOrigins}><span className={classes.label}>Population Origins: </span></p>

                <p className={classes.population}>
                    Los Angeles County(3,147)
                    <br />Fresno County (2,013)
                    <br />Sacremento County (993)
                    <br />Honolulu County, Hawaii (445)
                </p>

                <p>The population was about an even split between rural and urban.</p>

                <p><span className={classes.label}>Via "Assembly Centers": </span> Most came from Merced (4,500) and Santa Anita (3,063) "Assembly Centers". </p>

                <p>The population was generally of half urban and half rural origins. </p>

                <p><span className={classes.label}>Project director:</span> Paul Taylor and W.O. "Doc" Melton.</p>

                <p><span className={classes.label}>Community Analysts: </span> Edgar C. McVoy and Rachel R. Sady.</p>

                <p><span className={classes.label}>Newpaper(s): </span> <em>Communique</em> (October 23, 1942 to February 26, 1943);
                <br /><em>Denson Tribune</em> (March 2, 1943 to June 6, 1944)</p>

                <p><span className={classes.label}>% who answered question 28 of the loyalty questionnaire positively: </span> 75.0%. Jerome had the highest percentage of persons answering negatively, giving a qualified answer, or refusing to answer. </p>

                <p><span className={classes.label}>Number and percentage of eligible citizen males inducted directly into ARMED FORCES: </span> 52 (0.9%) Jerome had the lowest percentage of eligible male citizens inducted into the armed forces besides Tule Lake. </p>

                <p><span className={classes.label}>Industry: </span> Jerome had a sawmill that produced goods for internal consumption.</p>

                <p><span className={classes.label}>History: </span> There were no guard towers at Jerome and the fences were low; this was the camp was surrounded by swamps inhabited by four species of the most deadly snakes in America. Farming here was difficult, but the completion in Novermber 1942 of a canal that drained off excess water resulted in some agricultural success.  </p>


                <p><span className={classes.label}> General Histories of Jerome include:</span></p>
                
                <ul className={classes.list}>
                <li>Russell Bearden, "The False Rumor of Tuesday: Arkansas's Internment of Japanese Americans." (Arkansas Historical Quarterly, 41.4 (1982), pp. 327-39 and</li>

                <li>"Life Inside Arkansas's Japanese American Relocation Centers." (Arkansas Historical Quarterly, 68 (Summer 1989); pp.169-96.)</li>

                <li>Carole Katsuko Yumiba, "An Educational History of the War Relocation Centers at Jerome and Rohwer, Arkansas, 1942-1945.", Dissertation, University of Southern California, 1979.</li>
                </ul>
                
                <p><span className={classes.label} >Source:</span> <em>Japanese American History: An A to Z Reference, 1868 to the Present</em>, by Brian Niiya. New York: Facts on File, 1993. This information is provided with the permission from the Japanese American National Museum and Brian Niiya, 1997. 
                </p>
                
                
            </Typography>
        </div>
    );
} 