import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dataContainer: {
        padding: '4rem 1rem 1rem 0rem',
        maxWidth: '40rem'        
    },
    body1: {
        
    },
    label: {
        fontWeight: 800,
        color: theme.palette.primary.main,
        marginTop: '.25rem',
        display: 'inline-block' 
    },
    researchHeading: {
        fontWeight: 800,
        color: '#f00'
    },
    factSheet: {
        color: theme.palette.tertiary.main,
    },
    subCat: {
        marginLeft: '1rem',
    },
    monospace: {
        
        marginLeft: '.5rem'
    },
    populationOrigins: {
        marginTop: '1rem',
        marginBottom: '.5rem'
    },
    population: {
        marginLeft: '1.5rem',
        marginTop: '.5rem',
        fontFamily: 'monospace',
    },
    smMap: {
        float: 'right',
        margin: '1.5rem 0 1.5rem 1.5rem'
    }
}));


export default function GranadaData() {
    const classes = useStyles();

    return (
        <div className={classes.dataContainer}>
            <Typography variant='h3' className={classes.researchHeading}>
                Research: Granada (Amache) Relocation Camp</Typography>
            <Typography variant='body1' className={classes.factSheet}>
                <p><span className={classes.label}>Location:</span> Prowers County, Colorado; located 14 miles east of Lamar and 20 miles west of the Kansas border in the Arkansas River Valley.</p>

                <img src={`usmap.sm.gif`} className={classes.smMap} alt="small map of Japanese-American internment camps in western USA" />

                <p><span className={classes.label}>Land: </span> Purchased from a private party. </p>
                <p><span className={classes.label}>Size:</span> 10,500 acres.</p>

                <p><span className={classes.label}>Climate: </span> Located on a hilltop at 3,500 foot above sealevel, this camp was arid and dusty, though not as severe as the Arizona camps.</p>

                <p><span className={classes.label}>Peak population:</span> 7,318; Granada was the least populated of the camps. </p>

                <p><span className={classes.label}>Date of peak: </span> February 1, 1943.</p>
                <p><span className={classes.label}>Opening date: </span> August 27, 1942.</p>

                <p><span className={classes.label}>Closing Date: </span> October 15, 1945.</p>
                



                <p className={classes.populationOrigins}><span className={classes.label}>Population Origins: </span></p>

                <p className={classes.subCat}>Of the 6,285 Americans interned here...</p>

                <p className={classes.population}>
                    3,181 were from Los Angeles County, CA
                    <br />696 were from Sonoma County, CA
                    <br />666 were from Yolo County, CA
                    <br />661 were from Stanislaus County, CA
                    <br />632 were from Sacremento County, CA
                    <br />449 were from Merced County, CA
                </p>

                <p><span className={classes.label}>Via "Assembly Centers": </span> Most came from Merced (4,500) and Santa Anita (3,063) "Assembly Centers". </p>

                <p>The population was generally of half urban and half rural origins. </p>

                <p><span className={classes.label}>Project director:</span> James G. Lindley</p>

                <p><span className={classes.label}>Community Analysts: </span> E. Adamson Hoebel, John Ralph McFarling, John A. Rademaker.</p>

                <p><span className={classes.label}>Newpaper(s): </span> <em>Granada Bulletin</em> (October 14-24, 1942); <em>Granada Pioneer</em> (October 28, 1942, to September 15, 1945). </p>

                <p><span className={classes.label}>% who answered question 28 of the loyalty questionnaire positively: </span> 99.8%</p>

                <p><span className={classes.label}>Number and percentage of eligible citizen males inducted directly into ARMED FORCES: </span> 494 (9.9%); Granada had the highest percentage of eligible males inducted into the armed forces.</p>

                <p><span className={classes.label}>Industry: </span> Granada had a silk screen poster shop that produced a quarter of a million posters for naval training.</p>

                <p><span className={classes.label}>History: </span> Though located in a farming area, the agricultural development of the camp was unimpressive. Granada was at one point plagued by a polio problem that caused the administration to cancel some activities and to stop issuing passes to the outside. </p>

                <p><span className={classes.label}>Source: </span> <em>Japanese American History: An A to Z Reference, 1868 to the Present</em>, by Brian Niiya. New York: Facts on File, 1993. This information is provided with the permission from the Japanese American National Museum and Brian Niiya, 1997.</p>
                
            </Typography>
        </div>
    );
} 