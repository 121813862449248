import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dataContainer: {
        padding: '4rem 1rem 1rem 0rem',
        maxWidth: '40rem'        
    },
    body1: {
        
    },
    label: {
        fontWeight: 800,
        color: theme.palette.primary.main,
        marginTop: '.25rem',
        display: 'inline-block' 
    },
    researchHeading: {
        fontWeight: 800,
        color: '#f00'
    },
    factSheet: {
        color: theme.palette.tertiary.main,
    },
    subCat: {
        marginLeft: '1rem',
    },
    monospace: {
        
        marginLeft: '.5rem'
    },
    populationOrigins: {
        marginTop: '1rem',
        marginBottom: '.5rem'
    },
    population: {
        marginLeft: '1.5rem',
        marginTop: '.5rem',
        fontFamily: 'monospace',
    },
    smMap: {
        float: 'right',
        margin: '1.5rem 0 1.5rem 1.5rem'
    },
    list: {
        marginLeft: '1rem       '
    }
}));


export default function TopazData() {
    const classes = useStyles();

    return (
        <div className={classes.dataContainer}>
            <Typography variant='h3' className={classes.researchHeading}>
                Research: Topaz Relocation Camp</Typography>
            <Typography variant='body1' className={classes.factSheet}>
                <p><span className={classes.label}>Location:</span> Millard County, Utah, near Abraham, 140 miles south of Salt Lake City. </p>

                <img src={`usmap.sm.gif`} className={classes.smMap} alt="small map of Japanese-American internment camps in western USA" />

                <p><span className={classes.label}>Land: </span> Mix of public domain land, land which had reverted to the county for non payment of taxes and land purchased from private parties. </p>

                <p><span className={classes.label}>Size:</span> 19,800 acres.</p>

                <p><span className={classes.label}>Climate: </span> Temperatures ranged from 106 degrees in summer to -30 degrees in winter; located at an elevation of 4,600 feet, the region was subject to a constant wind that resulted in frequent dust storms. </p>
                
                <p className={classes.populationOrigins}><span className={classes.label}>Population Origins: </span></p>               
                <p className={classes.population}>Primarily California: 
                    <br />Alameda County County (3,679)
                    <br />San Francisco County (3,370)
                    <br />San Mateo County (722)
                </p>
                <p>The population was almost completely urban in origin.</p>

                <p><span className={classes.label}>Opening date: </span> September 11, 1942.</p> 
                <p><span className={classes.label}>Peak population:</span> 8,130. </p>
                <p><span className={classes.label}>Date of peak: </span> March 17, 1943.</p>                
                <p><span className={classes.label}>Closing Date: </span> October 31, 1945.</p>
                

                <p><span className={classes.label}>Project director:</span> Charles F. Ernst and Luther T. Hoffman.</p>

                <p><span className={classes.label}>Community Analysts: </span> Oscar F. Hoffman and Weston LaBarre.</p>

                <p><span className={classes.label}>Newpaper(s): </span> <em>Topaz Times</em> (September 17, 1942 to August 31, 1945)</p>

                <p><span className={classes.label}>% who answered question 28 of the loyalty questionnaire positively: </span> 89.4%</p>

                <p><span className={classes.label}>Number and percentage of eligible citizen males inducted directly into ARMED FORCES: </span> 472 (7.3%).</p>

                <p><span className={classes.label}>History: </span> Topaz featured an organized protest against the registration questionnaire, in which a petition was circulated demanding the restoration of rights as a prerequisite for registration. Issei chef James Hatsuki Wakasa was shot to death by a guard on April 11, 1943. The literary and arts magazine Trek was produced here. </p>
                
                <p><span className={classes.label} >Source:</span> <em>Japanese American History: An A to Z Reference, 1868 to the Present</em>, by Brian Niiya. New York: Facts on File, 1993. This information is provided with the permission from the Japanese American National Museum and Brian Niiya, 1997. 
                </p>
                
                <p><span className={classes.label}>Edited for exhibition by Masumi Hayashi, 1994. </span></p>
                
            </Typography>
        </div>
    );
} 