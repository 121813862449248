import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dataContainer: {
        padding: '4rem 1rem 1rem 0rem',
        maxWidth: '40rem'        
    },
    body1: {
        
    },
    label: {
        fontWeight: 800,
        color: theme.palette.primary.main,
        marginTop: '.25rem',
        display: 'inline-block' 
    },
    researchHeading: {
        fontWeight: 800,
        color: '#f00'
    },
    factSheet: {
        color: theme.palette.tertiary.main,
    },
    subCat: {
        marginLeft: '1rem',
    },
    monospace: {
        
        marginLeft: '.5rem'
    },
    populationOrigins: {
        marginTop: '1rem',
        marginBottom: '.5rem'
    },
    population: {
        marginLeft: '1.5rem',
        marginTop: '.5rem',
        fontFamily: 'monospace',
    },
    smMap: {
        float: 'right',
        margin: '1.5rem 0 1.5rem 1.5rem'
    },
    list: {
        marginLeft: '1rem       '
    }
}));


export default function ManzanarData() {
    const classes = useStyles();

    return (
        <div className={classes.dataContainer}>
            <Typography variant='h3' className={classes.researchHeading}>
                Research: Minidoka Relocation Camp</Typography>
            <Typography variant='body1' className={classes.factSheet}>
                <p><span className={classes.label}>Location:</span> Jerome County, south central Idaho, 6 miles north of Eden.</p>

                <img src={`usmap.sm.gif`} className={classes.smMap} alt="small map of Japanese-American internment camps in western USA" />

                <p><span className={classes.label}>Land: </span> Federal reclamation project land, part of the Gooding Reclamation District.</p>

                <p><span className={classes.label}>Size:</span> 33,500 acres.</p>

                <p><span className={classes.label}>Climate: </span> Severe, plagued by dust storms.</p>
                
                <p className={classes.populationOrigins}><span className={classes.label}>Population Origins: </span></p>               
                <p className={classes.population}>
                    King County, WA (6,098)
                    <br />Multnomah County, OR (1,927)
                    <br />Pierce County, WA (1,051)
                </p>
                <p>The poplulation was from mostly urban areas. </p>

                <p><span className={classes.label}>Opening date: </span> August 10, 1942.</p> 
                <p><span className={classes.label}>Date of peak: </span> March 1, 1943.</p>                
                <p><span className={classes.label}>Peak population:</span> 9,397.</p>
                <p><span className={classes.label}>Closing Date: </span> October 28, 1945.</p>
                

                <p><span className={classes.label}>Project director:</span> Harry Stafford</p>

                <p><span className={classes.label}>Community Analysts: </span>Gordon Armbruster, John de Young, and Elmer R. Smith.</p>

                <p><span className={classes.label}>Newpaper(s): </span> <em>Minidoka Irrigator</em>, (September 10, 1942 to July 28, 1945).</p>

                <p><span className={classes.label}>% who answered question 28 of the loyalty questionnaire positively: </span> 98.7%</p>

                <p><span className={classes.label}>Number and percentage of eligible citizen males inducted directly into ARMED FORCES: </span> 594 (8.8%).</p>

                <p><span className={classes.label}>Industry: </span> Minidoka had a garment factory which produced goods for internal consumption. </p>

                <p><span className={classes.label}>History: </span> Minidoka was regarded as the "best" of the camps whose positive atmosphere stemmed from the relatively homogenous population and the relatively benevolent administration. Because it was not in the Western Defense Command restricted area, its security was lighter than that of other camps. </p>
                
                <p><span className={classes.label} >Source:</span> <em>Japanese American History: An A to Z Reference, 1868 to the Present</em>, by Brian Niiya. New York: Facts on File, 1993. This information is provided with the permission from the Japanese American National Museum and Brian Niiya, 1997. 
                </p>
                
                
            </Typography>
        </div>
    );
} 