import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dataContainer: {
        padding: '4rem 1rem 1rem 0rem',
        maxWidth: '40rem'        
    },
    body1: {
        
    },
    label: {
        fontWeight: 800,
        color: theme.palette.primary.main,
        marginTop: '.25rem',
        display: 'inline-block' 
    },
    researchHeading: {
        fontWeight: 800,
        color: '#f00'
    },
    factSheet: {
        color: theme.palette.tertiary.main,
    },
    subCat: {
        marginLeft: '1rem',
    },
    monospace: {
        
        marginLeft: '.5rem'
    },
    populationOrigins: {
        marginTop: '1rem',
        marginBottom: '.5rem'
    },
    population: {
        marginLeft: '1.5rem',
        marginTop: '.5rem',
        fontFamily: 'monospace',
    },
    smMap: {
        float: 'right',
        margin: '1.5rem 0 1.5rem 1.5rem'
    },
    list: {
        marginLeft: '1rem       '
    }
}));


export default function RohwerData() {
    const classes = useStyles();

    return (
        <div className={classes.dataContainer}>
            <Typography variant='h3' className={classes.researchHeading}>
                Research: Rohwer Relocation Camp</Typography>
            <Typography variant='body1' className={classes.factSheet}>
                <p><span className={classes.label}>Location:</span> Desha County, southeastern Arkansas</p>

                <img src={`usmap.sm.gif`} className={classes.smMap} alt="small map of Japanese-American internment camps in western USA" />

                <p><span className={classes.label}>Land: </span> Farm Security Administration land. </p>

                <p><span className={classes.label}>Size:</span> 10,161 acres.</p>

                <p><span className={classes.label}>Climate: </span> Wooded swamp land; high heat and humidity, with sudden rains. </p>
                
                <p className={classes.populationOrigins}><span className={classes.label}>Population Origins: </span></p>               
                <p className={classes.population}>
                    Los Angeles County (4,324)
                    <br />San Joaquin County (3,516)
                </p>
                <p>Most of the population was urban.</p>

                <p><span className={classes.label}>Opening date: </span> September 18, 1942.</p> 
                <p><span className={classes.label}>Peak population:</span> 8,475 </p>
                <p><span className={classes.label}>Date of peak: </span> March 11, 1943.</p>                
                <p><span className={classes.label}>Closing Date: </span> November 30, 1945.</p>
                

                <p><span className={classes.label}>Project director:</span> Raymond Johnson.</p>

                <p><span className={classes.label}>Community Analysts: </span> Margaret Lantis, Katherine Luomala and Charles Wisdom.</p>

                <p><span className={classes.label}>Newpaper(s): </span> <em>Rohwer Outpost</em> (October 24, 1942 to July 21, 1945).</p>

                <p><span className={classes.label}>% who answered question 28 of the loyalty questionnaire positively: </span> 94.9%</p>

                <p><span className={classes.label}>Number and percentage of eligible citizen males inducted directly into ARMED FORCES: </span> 274 (4.7 %).</p>

                <p><span className={classes.label}>History: </span> In an early episode, inmate volunteers clearing brush were taken off to a local jail at gunpoint by locals who thought they were Japanese paratroopers. Because of the irregular weather, farming was difficult here despite relatively fertile soil. The climate also led to problems with mosquitos and chiggers. </p>

                <p><span className={classes.label}>See Also:</span></p>

                <ul>
                    <li>Carole Katsuko Yumiba, "An Educational History of the War Relocation Centers at Jerome and Rohwer, Arkansas, 1942-1945.", <em>Arkansas Historical Quarterly 69</em> (Summer 1989); pp 169-96. </li>

                </ul>
                
                <p><span className={classes.label} >Source:</span> <em>Japanese American History: An A to Z Reference, 1868 to the Present</em>, by Brian Niiya. New York: Facts on File, 1993. This information is provided with the permission from the Japanese American National Museum and Brian Niiya, 1997. 
                </p>
                
                
            </Typography>
        </div>
    );
} 