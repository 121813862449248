import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dataContainer: {
        padding: '4rem 1rem 1rem 0rem',
        maxWidth: '40rem'        
    },
    body1: {
        
    },
    label: {
        fontWeight: 800,
        color: theme.palette.primary.main,
        marginTop: '.25rem',
        display: 'inline-block' 
    },
    researchHeading: {
        fontWeight: 800,
        color: '#f00'
    },
    factSheet: {
        color: theme.palette.tertiary.main,
    },
    subCat: {
        marginLeft: '1rem',
    },
    monospace: {
        
        marginLeft: '.5rem'
    },
    populationOrigins: {
        marginTop: '1rem',
        marginBottom: '.5rem'
    },
    population: {
        marginLeft: '1.5rem',
        marginTop: '.5rem',
        fontFamily: 'monospace',
    },
    smMap: {
        float: 'right',
        margin: '1.5rem 0 1.5rem 1.5rem'
    },
    list: {
        marinLeft: '1rem',
    },
    postonStrike: {
        marginTop: '1rem',
    }
}));


export default function PostonData() {
    const classes = useStyles();

    return (
        <div className={classes.dataContainer}>
            <Typography variant='h3' className={classes.researchHeading}>
                Research: Poston Relocation Camp</Typography>
            <Typography variant='body1' className={classes.factSheet}>
                <p><span className={classes.label}>Location:</span> Yuma County, Arizona, 17 miles south of Parker.</p>

                <img src={`usmap.sm.gif`} className={classes.smMap} alt="small map of Japanese-American internment camps in western USA" />

                <p><span className={classes.label}>Land: </span> On the Colorado indian Reservation. Poston was the only camp to administered by the Office of Indian Affairs (OIA) (until late 1943) rather than the War Relocation Authority (WRA).</p>

                <p><span className={classes.label}>Size:</span> 71,000 acres.</p>

                <p><span className={classes.label}>Climate: </span> Desert, perhaps the hottest of all the camps.</p>
                
                <p className={classes.populationOrigins}><span className={classes.label}>Population Origins: </span></p>               
                <p className={classes.population}>
                    Los Angeles County (2,750)
                    <br />Tulare County (1,952)
                    <br />San Diego County (1,883)
                    <br />Orange County (1,636)
                    <br />Fresno County (1,590)
                    <br />Imperial County (1,512)
                    <br />Monterey County (1,506)
                    <br />Santa Cruz County (1,222)
                </p>
                <p>Most people came from rural areas.</p>

                <p><span className={classes.label}>Opening date: </span> May 8, 1942.</p> 
                <p><span className={classes.label}>Date of peak: </span> September 2, 1942.</p>                
                <p><span className={classes.label}>Peak population:</span> 17,814, the most populated except for Tule Lake "Segregation Center". </p>
                <p><span className={classes.label}>Closing Date: </span> Unit I: November 28, 1945;
Units II and III: September 29, 1945. </p>
                

                <p><span className={classes.label}>Project director:</span> Wade Head and Duncan Mills.</p>

                <p><span className={classes.label}>Community Analysts: </span> Alexander Leighton, Edward H. Spicer, Elizabeth Colson and David H. French; Conrad Arensberg and Laura Thompson were consultants. </p>

                <p><span className={classes.label}>Newpaper(s): </span> <em>Poston Chronical</em> (May 13, 1942 to October 23, 1945). </p>

                <p><span className={classes.label}>% who answered question 28 of the loyalty questionnaire positively: </span> 93.7%</p>

                <p><span className={classes.label}>Number and percentage of eligible citizen males inducted directly into ARMED FORCES: </span> 611 (4.8%).</p>

                <p><span className={classes.label}>Industry: </span> A camouflage net factory operated from fall 1942, to May 1943. </p>

                <p><span className={classes.label}>History: </span> The most notable incident at Poston was the POSTON STRIKE, described in detail in the following entry. Another strike, involving 57 adobe workers in August 1942 was quickly settled. Poston was named after Charles Poston, the "Father of Arizona". One of the most intensively studied camps, Poston housed a social science laboratory under the leadership of Alexander Leighton while under the OIA in addition to having WRA community analysts, and Japanese American Evacuation and Resettlement Study fieldworkers.</p>

                <p>Tensions between the OIA and the WRA led to the latter taking over administration of Poston at the beginning of 1944. The OIA had ideas of starting large scale farming ventures with the Japanese Americans on a semi permanent basis; this conflicted with the WRA's strategy of encouraging "loyal" residents to leave for resettlement as soon as possible. </p>


                <Typography variant='h4' className={classes.postonStrike} >Poston Strike:</Typography>

                <p>The strike was the manifestation of long standing tensions in the community exacerbated by the camp environment. By War Relocation Authority decree, only Nisei (second generation Japanese Americans) were allowed to hold elected office, and the Community Council at Poston consisted entirely of young nisei. The nisei council quickly alienated the administration by being too inquisitve and upset the Issei (first generation Japanese Americans) residents who distrusted the Council's orientation (most of the council were members of the Japanese American Citizens' League), and their inexperience. The council was seen by many as "administration stooges" or as a "child counsel". To try to bring issei into the decision making process, the Issei Advisory Board was formed in August, 1942, but the lack of power granted to this group increased the tensions. Competition between the Community Council and this advisory council ensued and people seen as "inu" ( or "dog", which is what the Japanese Americans called lackeys for the administration) were physically attacked.</p>

                <p>On November 14, 1942, one such inmate was beaten severely with a piece of pipe. Quickly, 50 suspects were arrested and two were held for further questioning. Both were quite popular among camp residents. When it became known that these men were to be tried in an Arizona court on the outside, protest erupted. It was widely percieved that no Japanese American could get a fair trial outside the camp. An issei delegation visited the project director on November 17 to request the suspect's release. They were refused. After a second unsuccessful meeting the following day, a general strike ensued. The Community Council resigned in support of the strike and in fear, and crowds began to gather around the jail holding the two suspects. The camp director had left the camp on business, leaving the camp in charge of the Assistant Director. With the beginning of the strike, some in the administration urged the Assistant Director to call in the army and to impose martial law, but instead, he chose to negotiate.</p>

                <p>In the next few days, all services except the police, fire department and hospital were closed down. Strike leaders stood around bonfires, played Japanese militaristic music and extolled the emperor. Eventually, administration made concessions, agreeing on November 23 to release one prisoner outright and to try the other within the center. Meanwhile, after 10 days, the strike began to erode as many nisei tired of it. The issei leaders were recognized by the administration and agreed to try to help stop the beatings and to establish a better rapport between administration and internees. </p>
                
                <p><span className={classes.label} >Source:</span> <em>Japanese American History: An A to Z Reference, 1868 to the Present</em>, by Brian Niiya. New York: Facts on File, 1993. This information is provided with the permission from the Japanese American National Museum and Brian Niiya, 1997. 
                </p>
                
                
            </Typography>
        </div>
    );
} 