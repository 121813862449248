import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dataContainer: {
        padding: '4rem 1rem 1rem 0rem',
        maxWidth: '40rem'        
    },
    body1: {
        
    },
    label: {
        fontWeight: 800,
        color: theme.palette.primary.main,
        marginTop: '.25rem',
        display: 'inline-block' 
    },
    researchHeading: {
        fontWeight: 800,
        color: '#f00'
    },
    factSheet: {
        color: theme.palette.tertiary.main,
    },
    subCat: {
        marginLeft: '1rem',
    },
    monospace: {
        
        marginLeft: '.5rem'
    },
    populationOrigins: {
        marginTop: '1rem',
        marginBottom: '.5rem'
    },
    population: {
        marginLeft: '1.5rem',
        marginTop: '.5rem',
        fontFamily: 'monospace',
    },
    smMap: {
        float: 'right',
        margin: '1.5rem 0 1.5rem 1.5rem'
    }
}));


export default function HeartMountainData() {
    const classes = useStyles();

    return (
        <div className={classes.dataContainer}>
            <Typography variant='h3' className={classes.researchHeading}>
                Research: Heart Mountain Relocation Camp</Typography>
            <Typography variant='body1' className={classes.factSheet}>
                <p><span className={classes.label}>Location:</span> Northwestern Wyoming, in Park County, 13 miles northeast of Cody.</p>

                <img src={`usmap.sm.gif`} className={classes.smMap} alt="small map of Japanese-American internment camps in western USA" />

                <p><span className={classes.label}>Land: </span> Federal reclamation project land.</p>
                <p><span className={classes.label}>Size:</span> 46,000 acres.</p>

                <p><span className={classes.label}>Climate: </span> Severe, even by WRA standards, with winter lows dipping to 30 degrees below zero. Elevation 4,600 feet.</p>

                <p><span className={classes.label}>Peak population:</span> 10,767.</p>

                <p><span className={classes.label}>Date of peak: </span> January 1, 1943.</p>
                <p><span className={classes.label}>Opening date: </span> August 12, 1942.</p>

                <p><span className={classes.label}>Closing Date: </span> November 10, 1945.</p>
                



                <p className={classes.populationOrigins}><span className={classes.label}>Population Origins: </span></p>

                <p className={classes.population}>
                    Los Angeles County(6,448)
                    <br />Santa Clara County (2,572)
                    <br />San Francisco County (678)
                    <br />Yakima/Washington Counties (843)
                </p>

                <p><span className={classes.label}>Project director:</span> Christopher E. Rachford and Guy Robertson.</p>

                <p><span className={classes.label}>Community Analysts: </span> Asael T. Hansen and Forrest La Violette.</p>

                <p><span className={classes.label}>Newpaper(s): </span> <em>Heart Mountain Sentinel</em> (October 24, 1942, to July 28, 1945).</p>

                <p><span className={classes.label}>% who answered question 28 of the loyalty questionnaire positively: </span> 95.9%</p>

                <p><span className={classes.label}>Number and percentage of eligible citizen males inducted directly into ARMED FORCES: </span> 385 (4.8%).</p>

                <p><span className={classes.label}>Industry: </span> Heart Mountain had a garment factory, a cabinet shop and a sawmill that produced goods for internal consumption. A silk screen shop produced posters for the other camps and for the navy.</p>

                <p><span className={classes.label}>History: </span> The weather, along with the shoddy construction of the barracks and a population mostly from Southern California cities, unaccustomed to the cold, contributed to a great many illnesses that resulted in hospital overcrowding in the winter of 1942-43. In addition to the severe climate, Heart Mountain, like many other camps was plagued by duststorms and rattlesnakes.</p>

                <p>Despite the inhospitability of the area, Heart was to become one of the most successful camps in terms of agriculture; many crops that had never been grown in the area before were introduced.</p>

                <p>Heart Mountain residents were stung by a series of muckraking articles about the camp by Denver Post reporter Jack Carberry, alleging, among other things, that the inmate population was being "coddled".</p>

                <p>Heart Mountain was the site of the only organized resistance to the military draft. Beginning in February 1944, the FPC organized in Heart Mountain around the issue of drafting Nisei from the concentration camps for military service. Citing the Constitution, the members of the FPC stated that they would not report to the draft board if called upon until their rights as citizens were restored.</p>

<br />
                <p><span className={classes.label}>Source: </span> <em>Japanese American History: An A to Z Reference, 1868 to the Present</em>, by Brian Niiya. New York: Facts on File, 1993. This information is provided with the permission from the Japanese American National Museum and Brian Niiya, 1997.</p>

                <p>There are a number of works specifically on Heart Mountain. For a general history of the camp, see:</p>

                <p>Douglas W. Nelson, <em>Heart Mountain: The History of an American Concentration Camp</em>. (Madison, Wisconsin: The State Historical Society of Wisconsin, 1976.)</p>

                <p>Rita Takahashi Cates, "Comparative Administration and Management of Five War Relocation Authority Camps: America's Incarceration of Persons of Japanese Descent during World War II." (Dissertation, University of Pittsburgh, 1980. This work studies the administration strategies of Heart Mountain and four other camps.)</p>

                <p>Asael T. Hansen, "My Two Years at Heart Mountain: The Difficult Role of an Applied Anthropologist." (In Daniels, Roger, Sandra C.Taylor, and Harry H.L. Kitano, eds.) <em>Japanese Americans: From Relocation to Redress</em>. Revised Edition. Seattle: University of Washington Press, 1991. Pp 33-37 is a reminiscence by the former "community analyst" at Heart Mountain)</p>

                <p><span className={classes.label}>Source:</span> <em>Japanese American History: An A to Z Reference, 1868 to the Present</em>, by Brian Niiya. New York: Facts on File, 1993. This information is provided with the permission from the Japanese American National Museum and Brian Niiya, 1997. </p>
                
            </Typography>
        </div>
    );
} 